import React from "react";
import Layout from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { FaLinkedin } from "react-icons/fa";

import "../scss/main.scss";

export default function PastResults() {
    const {
        allResultsJson: { edges: results },
    } = useStaticQuery(graphql`
    {
      allResultsJson {
        edges {
          node {
            id
            name
            result {
              exam
              score
            }
            linkedin
            avatar {
              childImageSharp {
                gatsbyImageData(width: 400, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `);

    return (
        <Layout pageTitle="Past Results | Ikshana Learning">
            <section
                className="hero about-hero-section"
                style={{
                    margin: "2.5rem 0 0 0",
                    // paddingBottom: "1rem",
                }}
            >
                <h1 className="hero__title">Past Results</h1>
                <h3 className="hero__subtitle" style={{ marginTop: "1.5rem" }}>
          Reflecting on Past Triumphs,
                    <br />
          Celebrating Our Achievements Together!
                </h3>
            </section>
            <section style={{ position: "relative" }}>
                <div className="programs__bg-layer">
                    <div className="programs__bg-layer__portion programs__bg-layer__portion--left"></div>
                    <div className="programs__bg-layer__portion programs__bg-layer__portion--right"></div>
                </div>
                <div
                    className="container programs__container"
                    style={{ paddingTop: "3rem" }}
                >
                    <h2 className="title programs__title">Reflecting on 2023</h2>
                    <h3
                        className="subtitle programs__subtitle"
                        style={{ marginBottom: "3rem" }}
                    >
            Recalling the Resilience and Successes
                    </h3>
                    <div className="results__flex">
                        {results.map(({ node }) => (
                            <div
                                key={node.id}
                                className="programs__flex__course results__flex__course"
                                style={{ width: "22rem", height: "26rem" }}
                            >
                                <GatsbyImage
                                    alt={node.name}
                                    image={node.avatar.childImageSharp.gatsbyImageData}
                                    loading="lazy"
                                    className="student-image"
                                />
                                {node.linkedin ? (
                                    <a
                                        href={node.linkedin}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="student__linkedin"
                                    >
                                        <FaLinkedin size="3rem" />
                                    </a>
                                ) : null}
                                <p
                                    className="title programs__flex__course__title"
                                    style={{ margin: "0.5rem 0" }}
                                >
                                    {node.name}
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                    }}
                                >
                                    {React.Children.toArray(
                                        node.result.slice(0, 4).map(({ exam, score }, idx) => (
                                            <p
                                                className="programs__flex__course__description__text"
                                                style={{ fontSize: "1.5rem" }}
                                            >
                                                {exam}({score})
                                                {idx < node.result.length - 1 ? "," : null}
                        &nbsp;
                                            </p>
                                        ))
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </Layout>
    );
}
